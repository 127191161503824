<template>
  <DoseCardBase ref="baseCard" :session-response="sessionResponse">
    <template #title>
      {{
        $t(`titration-time-point-header.${recommendation.treatment.timepoint}`)
      }}
    </template>

    <UnderlyingData
      v-if="recommendation.underlyingData"
      :insulins="recommendation.underlyingData.insulins"
      :smbgs="recommendation.underlyingData.smbgs"
      :underlying-data-context="underlyingDataContext.ADD_DOSE"
      :patient-no="sessionResponse.patientNumber"
      :treatment-timepoint="recommendation.treatment.timepoint"
      :derived-dose="derivedDailyRecommendation"
    />

    <v-card-text>
      <v-form>
        <v-row>
          <v-col
            sm="5"
            v-if="recommendation.lastPrescribedDose"
            class="text-color-muted"
          >
            <span class="text-color-muted mb-1">
              Last prescribed:
              <DateFormat
                :value="recommendation.lastPrescribedDose.doneAt.time"
              />
              <br />
              {{
                $t(
                  'time-point-short.' +
                    recommendation.lastPrescribedDose.timepoint
                )
              }}
              dose:
              <span class="font-weight-bold"
                >{{ lastPrescribedDoseValue }}
                {{ lastPrescribedDosingDisplayUnit }}
              </span>
              <br />
              {{
                $t(
                  'time-point-short.' +
                    derivedDailyRecommendation.lastPrescribedDose.timepoint
                )
              }}
              dose:
              <span class="font-weight-bold"
                >{{ derivedDailyRecommendation.lastPrescribedDose.doseValue }}
                {{
                  derivedDailyRecommendation.lastPrescribedDose
                    .dosingDisplayUnit
                }}
              </span>
            </span>
          </v-col>
          <v-col
            sm="7"
            :class="{ 'offset-5': !recommendation.lastPrescribedDose }"
          >
            <v-row class="d-flex">
              <v-col
                class="text-right spacing-2 pb-0 effective-date-label-text pt-5"
              >
                Expected dosing date
              </v-col>
              <v-col class="pb-0">
                <v-select
                  v-model="selectedEffectiveDosingDay"
                  :items="effectiveDateOptions"
                  :rules="effectiveDateRules"
                  placeholder="Select a date"
                  outlined
                  dense
                  class="rounded-lg"
                  :disabled="isStepInputReason"
                  @change="addEffectiveDosingDayAuditEntry($event)"
                  ref="effectiveDosingDaySelector"
                >
                </v-select>
              </v-col>
            </v-row>

            <DoseInput
              :dose-ok.sync="doseOk"
              :step.sync="currentStep"
              v-model="dose"
              :validation-rules="recommendation.treatment.allowedDoseRange"
              :dose-deviation-validation-rules="
                recommendation.treatment.allowedDoseDeviation
              "
              :last-prescribed-dose-value="lastPrescribedDoseValue"
              :has-effective-dosing-day="true"
              @reset="resetDoubleDummyInput"
              :timepoint="recommendation.treatment.timepoint"
              :dosing-display-unit="recommendation.treatment.dosingDisplayUnit"
              @auditEvent="
                addAuditEntry('PRESCRIBE_DOSE:WEEKLY_DOSE_VALUE', $event)
              "
              @input="deriveDailyDose()"
            />

            <DerivedDoseInput
              :display-value="doseDerivedDaily"
              :trial-site-no="trialSiteNumber"
              :dosing-display-unit="
                derivedDailyRecommendation.treatment.dosingDisplayUnit
              "
            />
          </v-col>
        </v-row>

        <v-slide-y-reverse-transition>
          <DoseReason
            v-if="isStepInputReason"
            v-model="doseReasonText"
            :deviationReasons="deviationReasons"
            :reason-ok.sync="doseReasonOk"
            :reason.sync="doseReason"
            @auditType="
              addAuditEntry('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TYPE', $event)
            "
            @auditReason="
              addAuditEntry('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TEXT', $event)
            "
            @scrollToBottom="scrollToBottom()"
          />
        </v-slide-y-reverse-transition>

        <v-row>
          <v-col class="text-right">
            <v-btn
              data-testid="add-confirm-button"
              rounded
              @click.prevent="validatePrescription"
              color="secondary"
              :disabled="!allowNextStep"
              :loading="isLoading"
              type="submit"
              elevation="1"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </DoseCardBase>
</template>

<script>
import UnderlyingData from '@/components/prescription/shared/UnderlyingData'
import DoseCardBase from './_DoseCardBase'
import Vue from 'vue'

import selectedPatientGettersMixin from '@/components/mixins/store/selectedPatientGettersMixin'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import addPrescriptionMixin from './addPrescriptionMixin'
import addBasalPrescriptionMixin from './addBasalPrescriptionMixin'
import DateFormat from '@/components/DateFormat.vue'
import DoseReason from './_DoseReason.vue'
import DoseInput from './_DoseInput.vue'
import DerivedDoseInput from './_DerivedDoseInput.vue'

import loadingState from '@/constants/loadingState'
import deviationReason from '@/constants/deviationReason'
import prescriptionService from '@/services/prescription-service'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'

import { debounce } from 'debounce'
import timepointKeys from '@/constants/timepointKeys'

export default {
  name: 'DoubleDummyRecurringDose',
  components: {
    DoseCardBase,
    UnderlyingData,
    DateFormat,
    DoseReason,
    DoseInput,
    DerivedDoseInput,
  },
  mixins: [
    selectedPatientGettersMixin,
    trialSettingsGettersMixin,
    addPrescriptionMixin,
    addBasalPrescriptionMixin,
  ],
  data() {
    return {
      deriveDailyLoadingState: loadingState.INITIAL,
      doseDerivedDaily: '',
    }
  },
  computed: {
    recommendation() {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === timepointKeys.basal_once_weekly
      )
    },
    derivedDailyRecommendation() {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === timepointKeys.basal_once_daily
      )
    },
    allowNextStep() {
      return (
        this.isInputValid &&
        this.deriveDailyLoadingState === loadingState.LOAD_SUCCEEDED
      )
    },
  },
  methods: {
    ...notificationMapActions(),
    // Note: This resetFormn() overwrites the addBasalPrescriptionMixin method,
    // because a special doseDeriveDaily input has to be cleared on form reset.
    resetForm() {
      this.resetDoubleDummyInput()
      this.selectedEffectiveDosingDay = null
      this.$refs.effectiveDosingDaySelector.reset()
    },
    resetDoubleDummyInput() {
      this.resetInput()
      this.doseDerivedDaily = ''
      this.auditLog.logReset('PRESCRIBE_DOSE:DAILY_DOSE_VALUE')
    },
    getDoses(hasDeviationReason) {
      const doseFloat = parseFloat(this.dose)
      const doseDerivedDailyFloat = parseFloat(this.doseDerivedDaily)
      const deviation = hasDeviationReason
        ? {
            deviationType: this.doseReason,
            text:
              this.doseReason !== deviationReason.OTHER
                ? null
                : this.doseReasonText,
          }
        : null

      const selectedEffectiveDosingDay = this.selectedEffectiveDosingDay - 1

      const doses = [
        {
          timepoint: this.recommendation.treatment.timepoint,
          isActive: true,
          selectedEffectiveDosingDay,
          doseValue: doseFloat,
          reasonForDeviation: deviation,
          auditEntries: this.auditLog.getAuditEntries(),
        },
        {
          timepoint: this.derivedDailyRecommendation.treatment.timepoint,
          isActive: true,
          selectedEffectiveDosingDay: null,
          doseValue: doseDerivedDailyFloat,
          reasonForDeviation: deviation,
          auditEntries: this.auditLog.getAuditEntries(),
        },
      ]

      return doses
    },
    deriveDailyDose() {
      if (!this.doseOk) {
        this.doseDerivedDaily = ''
        this.deriveDailyLoadingState = loadingState.INITIAL
        return
      }
      this.deriveDailyLoadingState = loadingState.LOADING

      const doseFloat = parseFloat(this.dose)
      prescriptionService
        .deriveDose(
          this.sessionResponse.patientNumber,
          this.trialSiteNumber,
          this.selectedPatientTreatmentArmId(
            this.sessionResponse.patientNumber
          ),
          doseFloat
        )
        .then(result => {
          const referenceWeekly = result.fromValue
          if (referenceWeekly === doseFloat) {
            this.deriveDailyLoadingState = loadingState.LOAD_SUCCEEDED
            const dose = result.doseValue.toString()
            this.doseDerivedDaily = dose
            this.addAuditEntry('PRESCRIBE_DOSE:DAILY_DOSE_VALUE', dose)
          }
        })
        .catch(error => {
          this.deriveDailyLoadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)

          this.spawnNotification({
            type: NotificationType.Error,
            title:
              'Weekly dose could not be translated to daily dose, please try again.',
          })
        })
    },
  },
  created() {
    this.deriveDailyDose = debounce(this.deriveDailyDose, 300)
  },
}
</script>

<style lang="scss" scoped>
.font-small {
  font-size: 14px;
}
.text-color-muted {
  color: $nn-D_T50;
}
.effective-date-label-text {
  font-size: 14px;
  font-weight: 500;
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    color: $nn-granite-gray;
    font-weight: bold;
    background-color: #f1f2f3;
  }
}
</style>
