<template>
  <div class="d-flex justify-end mt-7">
    <div class="derived-label">
      Corresponding <br />
      daily dose
      <DoubleDummyTitrationTable :trial-site-no="trialSiteNo" />
    </div>

    <div class="derived-input rounded-lg d-flex justify-end ml-2">
      <div class="align-self-center" data-testid="derived-input">
        {{ displayValue }}
      </div>
      <div class="align-self-center pl-2">{{ dosingDisplayUnit }}</div>
    </div>
  </div>
</template>

<script>
import DoubleDummyTitrationTable from '@/components/prescription/shared/_DoubleDummyTitrationTable.vue'

export default {
  name: 'DerivedDoseInput',
  components: { DoubleDummyTitrationTable },
  props: {
    displayValue: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    trialSiteNo: { type: String, required: true },
    dosingDisplayUnit: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.derived-label {
  font-size: 14px;
}

.derived-input {
  color: $nn-granite-gray;
  background-color: $nn-SB_T98;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  width: 106px;
}
</style>
