import { render, staticRenderFns } from "./_DoubleDummyRecurringDose.vue?vue&type=template&id=30670545&scoped=true"
import script from "./_DoubleDummyRecurringDose.vue?vue&type=script&lang=js"
export * from "./_DoubleDummyRecurringDose.vue?vue&type=script&lang=js"
import style0 from "./_DoubleDummyRecurringDose.vue?vue&type=style&index=0&id=30670545&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30670545",
  null
  
)

export default component.exports